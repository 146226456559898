import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'


class CardChildren extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            nom: "",
            prenom: "",
            section: "",
            datenaiss: "",
            email: "",
            commentaire: ""
        };
        this.downloadCsvFile = this.downloadCsvFile.bind(this)
    };
 
    downloadCsvFile() {
        var csvFileData = this.props.children;
        var csv = 'Naissance;Nom;Prénom;Section;Commentaire;Email\n';
        csvFileData.forEach(function (row) {
            delete row._id
            if (row.commentaire === null) {
                row.commentaire = " "
            }
            Object.values(row)
            csv += Object.values(row).join(';');
            csv += "\n";
        });
        console.log(csv)

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Listing40Bierges.csv';
        hiddenElement.click();
    }

    render() {
        return (
            <>
                <section>
                    <h2>Liste des animés</h2>
                    <article className="admin-card">
                        {this.props.children.map((child) => {
                            //let dateNumbers = child.datenaiss.split('T')[0].split('-')
                            return (
                                <div id={child._id} className="containerItemAdmin">
                                    <h3>{child.nom} {child.prenom}</h3>
                                    <p> {child.section}</p>
                                    {/* <p> {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</p> */}
                                    <p> {child.email}</p>
                                    <p> {child.commentaire}</p>
                                </div>
                            )
                        })}
                    </article>
                    <br /><br /><br /><a onClick={this.downloadCsvFile} id="btn-subscribe" className="text-center">Télécharger un fichier excel</a>
                </section>
            </>
        );
    }
}
export default CardChildren;