import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";


class AddMeeting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            sectionMeeting: "NULL",
            role: "CHEF",
            date: null,
            infos: "",
            libelle: ""
        };

        this.handleNewMeetingClick = this.handleNewMeetingClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleNewMeetingClick() {
        axios.post(this.state.url + '/createMeeting', {
            date: this.state.date,
            infos: this.state.infos,
            libelle: this.state.libelle,
            sectionMeeting: this.state.sectionMeeting
        }, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            console.log(response)
            alert("La réunion a bien été crée")
        }).catch(error => {
            console.log(error)
        });
        
    }

    render() {
        return (
            <>
                <section>
                    <h2>Ajouter une réunion :</h2>
                    <div className="form">
                        <div className="champ">
                            <label htmlFor="date">Date </label>
                            <input type="date" name="date" id="date" onChange={this.handleChange} />
                            <label htmlFor="infos">Heure et lieu</label>
                            <input type="text" name="infos" id="infos" placeholder="14h-18h : Au local" onChange={this.handleChange} />
                            <label htmlFor="libelle">Libellé</label>
                            <input type="text" name="libelle" id="libelle" placeholder="Réunion" onChange={this.handleChange} />
                            <label htmlFor="sectionMeeting" >Section : </label>
                            <select name="sectionMeeting" id="sectionMeeting" onChange={this.handleChange}>
                                <option value="CHEFS" selected>ACTIVITE CHEF</option>
                                <option value="NUTONS">NUTONS</option>
                                <option value="LUTINS">LUTINS</option>
                                <option value="LOUPS">LOUPS</option>
                                <option value="GUIDES">GUIDES</option>
                                <option value="SCOUTS">SCOUTS</option>
                                <option value="HORIZONS">HORIZONS</option>
                                <option value="ROUTE">ROUTE</option>
                            </select>
                        </div>
                        <div className="champ">
                            <br /><br /><br /><p className='text-center'><a type="submit" id="btn-subscribe" onClick={this.handleNewMeetingClick}>Créer</a></p>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

export default AddMeeting;