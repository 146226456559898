import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Footer from "components/Footers/Footer.js";
import '../assets/css/main.css'
import '../assets/css/subscribe.css'

import Navbar from "components/Navbars/Navbar";
export default function ContactUs() {

    const [ setRedirect] = useState(false);

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        let nbrOne = Math.floor(Math.random() * 11);
        let nbrTwo = Math.floor(Math.random() * 11);
        let valueAns = Number.parseInt(prompt("Combien font " + nbrOne + " + " + nbrTwo + " ?"));
        if (valueAns === nbrOne + nbrTwo) {
            emailjs.sendForm('gmail', 'inscription', e.target, 'user_HaYuBloGmg7P4CBJTpgxQ')
                .then((result) => {
                    document.getElementById("myForm").reset();
                    alert("Votre demande a bien été enregistrée, vous recevrez un mail dans un instant !")
                    setRedirect(true);
                }, (error) => {
                    console.log(error.text);
                    alert("Il y a eu une erreur du système. Veuillez réessayer. Si le souci persiste, envoyez un mail à 40bierges@gmail.com")
                });
        } else {
            alert("Mauvaise réponse...")
        }
    }

 
    return (
        <>
            <Navbar instagramLink="https://www.instagram.com/horizonsbierges/" />
            <section className=" section-home home-subscribe">
                <h1>Inscription</h1>
            </section>
            <main>
                <section className='section-subscribe'>
                    <article className='intro-subscribe'>
                        <p id='display-desktop'>Lorsque vous aurez reçu une confirmation positive de notre part, nous accueillerons avec impatience votre enfant lors de la première ou de la prochaine réunion !</p><br />
                            <p id='display-desktop'>Adresse : <a className="btn-text" href="https://www.google.be/maps/place/Rue+Saint-Pierre+8,+1301+Wavre/@50.7100116,4.5893294,157m/data=!3m1!1e3!4m5!3m4!1s0x47c17d80de26b8a1:0x87afb5abba81996e!8m2!3d50.7099547!4d4.589577" target="_blank" rel="noopener noreferrer">Rue Saint-Pierre 8 à Bierges (derrière l’église)</a>. </p>
                            <p id='display-desktop'>mail: <a className="btn-text" href="mailto:40bierges@gmail.com"> 40bierges@gmail.com</a></p>
                    </article>

                    <form name="my-form" className='form' id="myForm" onSubmit={sendEmail}>
                        <div class="champ">
                            <label for="from_name">Nom et prénom du parent : </label>
                            <input type="text" id="from_name" name="from_name" required/>

                            <label for="from_email">Adresse email de contact :</label>
                            <input type="email" id="from_email" name="from_email" required />

                            <label for="contact_phone">Numéro de téléphone du parent : </label>
                            <input type="number" id="contact_phone" name="contact_phone" required/>
                        </div>

                        <div class="champ">
                            <label for="child_name">Nom et prénom de l'enfant : </label>
                            <input type="text" id="child_name" name="child_name" required/>

                            <label for="child_birthday">Date de naissance de l'enfant : </label>
                            <input type="date" id="child_birthday" name="child_birthday" required />

                            <label for="child_adress">Adresse de l'enfant : </label>
                            <input type="text" id="child_adress" name="child_adress" required/>
                        </div>

                        <div class="champ">
                            <label for="comments" >Commentaire supplémentaire</label>
                            <textarea id="comments" cols="40" rows="4" name="comments"></textarea>
                        </div>
                        <div class="champ">
                            <br /><br /><br /><button className='text-center'><a type="submit" id="btn-subscribe">Envoyer les informations</a></button>
                        </div>
                    </form>
                    <div id='display-mobile'>
                    <p>Lorsque vous aurez reçu une confirmation positive de notre part, nous accueillerons avec impatience votre enfant lors de la première ou de la prochaine réunion !</p><br />
                            <p>Adresse : <a className="btn-text" href="https://www.google.be/maps/place/Rue+Saint-Pierre+8,+1301+Wavre/@50.7100116,4.5893294,157m/data=!3m1!1e3!4m5!3m4!1s0x47c17d80de26b8a1:0x87afb5abba81996e!8m2!3d50.7099547!4d4.589577" target="_blank" rel="noopener noreferrer">Rue Saint-Pierre 8 à Bierges (derrière l’église)</a>. </p>
                            <p>mail: <a className="btn-text" href="mailto:40bierges@gmail.com"> 40bierges@gmail.com</a></p>
                        </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

