import React from "react";

// reactstrap components
import '../assets/css/main.css'
import lutin from '../assets/img/loups.jpg'
import nuton from '../assets/img/nutons.jpg'
import aventure from '../assets/img/aventure.jpg'
import pi from '../assets/img/pis.jpg'

function SectionCards() {
    return (
        <article>
            <figure>
                <a href="/nutons">
                    <img src={nuton} alt="écusson des nutons" />
                        <p>Les nutons<br/>
                        5-7 ans<br/>
                        mixtes</p>
                </a>
            </figure>
            <figure>
                <a href="/lutins">
                    <img src={lutin} alt="écusson des lutins" />
                        <p>Les lutins<br/>
                        8-12 ans<br/>
                        filles</p>
                </a>
            </figure>
            <figure>
                <a href="/loups">
                    <img src={lutin} alt="écusson des loups" />
                    <p>Les loups<br/>
                    8-12 ans<br/>
                    garçons</p>
                </a>
            </figure>
            <figure>
                <a href="/guides">
                    <img src={aventure} alt="écusson des guides" />
                    <p>Les aventures<br/>
                    12-16 ans<br/>
                    filles</p>
                </a>
            </figure>
            <figure>
                <a href="/scouts">
                    <img src={aventure} alt="écusson des scouts" />
                    <p>Les aventuriers<br/>
                    12-16 ans<br/>
                    garçons</p>
                </a>
            </figure>
            <figure>
                <a href="/pionniers">
                    <img src={pi} alt="écusson des pionniers" />
                    <p>Les horizons<br/>
                    6-17 ans<br/>
                    mixtes</p>
                </a>
            </figure>
        </article>

    );
}

export default SectionCards;


