import React from "react";

// reactstrap components

// core components
import Navbar from "components/Navbars/Navbar";
import Header from "components/Headers/MainHeader.js";
import Footer from "components/Footers/Footer.js";
import SectionCards from "components/SectionCards";
import ValeursCards from "components/ValeursCards";
import '../assets/css/main.css'


function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <Navbar instagramLink="https://www.instagram.com/40e_bierges/" />
      <Header />
      <div className="html">
        <main>
          <section>
            <h2 className="banner-text">Apprendre à vivre en groupe et à développer sa confiance en soi tout en s’amusant !</h2>
          </section>
          <section className="section-valeurs">
            <h2 className="PartTitle">Nos valeurs</h2>
            <ValeursCards></ValeursCards>
            <h3>Nous proposons un encadrement par des chefs passionnés attentifs au développement de chacun des enfants.</h3>
            <p>Faire partie de la 40e, c’est faire partie d’une famille !<br />
              Serez-vous le prochain à nous rejoindre ?</p>
            <br /><br /><br /><p className="text-center"><a className="btn" href="/subscribe">Inscription</a></p>
          </section>

          <section className="section-sections">
            <h2 >Les sections</h2>
            <h3>Notre unité est adaptée à tous les âges et est divisée en 6 sections</h3>
            <SectionCards></SectionCards>
          </section>

          <section className="section-activites">
            <h2>Les activités</h2>
            <h3>Les activités que nous proposons sont divisées en 3 catégories</h3>
            <ul className="list-item">
              <li>Le Grand Camp. Il se déroule la deuxième quinzaine de juillet et se déroule par section.</li>
              <li>Les Weekends. Un weekend de section et un weekend d’unité sont organisés sur l’année.</li>
              <li>Les réunions. En moyenne, elle se déroulent une semaine sur deux, le samedi après-midi. Elles peuvent être adaptées par des activités ou remplacée par des journées spéciales.</li>
            </ul>
            <p>Il est vivement conseillé de participer à l’ensemble du programme.</p>
          </section>

          <section className="section-propos">
            <h2>A propos</h2>
            <h3> Adresse : <a className="btn-text" href="https://www.google.be/maps/place/Rue+Saint-Pierre+8,+1301+Wavre/@50.7100116,4.5893294,157m/data=!3m1!1e3!4m5!3m4!1s0x47c17d80de26b8a1:0x87afb5abba81996e!8m2!3d50.7099547!4d4.589577" target="_blank" rel="noopener noreferrer">Rue Saint-Pierre 8 à Bierges (derrière l’église)</a>.</h3>
            <p>Nous disposons de nos propre locaux où nous serons ravis de vous y accueillir !</p>
            <p>Fédération : notre unité est chapeautée par les <a className="btn-text" href="https://guides.be/" target="_blank" rel="noopener noreferrer">Guides</a>.</p>
            <br />
            <p>Pourquoi notre unité ?</p>
            <ul className="list-item">
              <li>Une unité de petite taille, avec une ambiance familiale qui laisse la place à chaque jeune de se développer à son rythme et de recevoir une attention toute particulière.</li>
              <li>Des animateurs respectueux et responsables qui sont formés pour créer des activités de qualité.</li>
              <li>Déjà 16 années d’existance durant lesquels des centaines de jeunes sont devenus des adultes ouverts d’esprits et à l’aise dans tous contextes et dans n’importe quels groupes.</li>
            </ul>
            <p>Pour toutes informations sur notre unité veuillez nous contacter par mail<br />
              mail: <a className="btn-text" href="mailto:40bierges@gmail.com"> 40bierges@gmail.com </a></p>
          </section>

          <section className="section-inscription">
            <h2 >Inscription</h2>
            <p>L’inscription se fait en quelques <a href="/subscribe" className="btn-text">cliques ici</a></p>
            <p>Lorsque vous aurez reçu une confirmation positive de notre part, nous accueillerons avec impatience votre enfant lors de la première ou de la prochaine réunion !</p>
            <br /><br /><br /><p className="text-center"><a className="btn" href="/subscribe">Inscription</a></p>
          </section>
        </main>
        <br />
        <Footer />
      </div>
    </>
  );
}

export default Index;
