import React from "react";

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import aventure from '../../assets/img/aventure.jpg'
import axios from "axios"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolleyball, faCompass, faPersonHiking, faCloudSun, faFlag } from '@fortawesome/free-solid-svg-icons'

const volleyball = <FontAwesomeIcon icon={faVolleyball} />
const compass = <FontAwesomeIcon icon={faCompass} />
const personHiking = <FontAwesomeIcon icon={faPersonHiking} />
const cloudSun = <FontAwesomeIcon icon={faCloudSun} />
const flag = <FontAwesomeIcon icon={faFlag} />

class MainScouts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetings: [],
            url: "https://bierges-40e-api.herokuapp.com"
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.state.url + '/fetchMeetings/SCOUTS').then(response => {
            this.setState({ meetings: response.data.meetings, isLoading: false })
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
                <Navbar instagramLink="https://www.instagram.com/troupe_escurial/" />
                <section className="section-home home-guide">
                    <h1>Les aventures</h1>
                    <p><em>La prise de responsabilités</em></p>
                </section>
                <main>
                    <section className="intro-nuton">
                        <article>
                            <img src={aventure} alt="" />
                        </article>
                        <article>
                            <p>La Compagnie des Aventuriers accueille des scouts de 13 à 17 ans et se compose de différentes patrouilles.</p>
                            <p>Dans chaque patrouille (équipe de 6 à 8 scouts), on retrouve un CP (chef de patrouille) et un SP (second de patrouille).<br />
                                Les autres Aventuriers de la patrouille ont également l’occasion de prendre différentes responsabilités : woodcraft, cuisine, pharmacie, …</p>
                        </article>
                    </section>
                    <section>
                        <h2>Être scout c'est...</h2>
                        <h3>S’engager et progresser sur le plan humain mais également sur le plan des compétences techniques. C’est apprendre à évoluer, s’épanouir en groupe, découvrir des valeurs humaines, être actif et s’engager dans des projets et vivre des aventures passionnantes.</h3>
                        <p>Dans la Compagnie, les adolescentes ont l’occasion d’assumer de plus grandes responsabilités, de s’engager dans de petits projets collectifs, à leur portée, utiles pour les autres et dans lesquels elles ont un rôle à jouer.
                            Les scouts évoluent sur les chemins de l’Aventure à travers 5 contrées :</p>
                        <article>
                            <figure className="background-white">
                                <div className="icone-section icone-scout">{volleyball}</div>
                                <h4>Plein jeu</h4>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section icone-scout">{compass}</div>
                                <h4>Quatre vents</h4>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section icone-scout">{cloudSun}</div>
                                <h4>Saison</h4>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section icone-scout">{personHiking}</div>
                                <h4>Troubadour</h4>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section icone-scout">{flag}</div>
                                <h4>Sans frontière</h4>
                            </figure>
                        </article>
                        <p> Ces contrées permettent aux jeunes de grandir, de progresser et d’assumer des responsabilités dans des projets variés. Les badges et le carnet de l'Aventure sont, dans ces buts, deux outils privilégiés.<br /><br />
                            Lors de son premier camp, le scout reçoit un totem ; un nom d’animal. La totémisation et la réalisation de défis personnels marquent son intégration dans la Troupe. Le totem est choisi dans le respect de l’individu en fonction de son aspect physique, ses attitudes, ses mimiques et son comportement général.<br /><br />
                            Plus tard, le quali vient compléter le totem. Il s’agit d’une phrase ou mot qui représente les qualités et le comportement de l’ado.<br /><br />
                            La devise « Toujours prête » est commune à tous les scouts et scouts du monde.</p>
                    </section>
                    <section>
                        <h2> La loi guide </h2>
                        <h3>Permet au jeune de découvrir des valeurs, de les expérimenter et de choisir d’y adhérer, notamment par la promesse.</h3>
                        <ul className="list-item">
                            <li>Le scout est vraie, il mérite et fait confiance.</li>
                            <li>Le scout est loyal, il tient ses engagements.</li>
                            <li>Le scout rend service.</li>
                            <li>Le scout veut être l’ami de tous.</li>
                            <li>Le scout est courtois, elle est attentionnée.</li>
                            <li>Le scout aime la nature et protège la vie, il peut y découvrir l’œuvre de Dieu.</li>
                            <li>Le scout sait obéir, il va jusqu’au bout.</li>
                            <li>Le scout partage sa joie de vivre.</li>
                            <li>Le scout respecte le bien commun et participe à l’effort de tous.</li>
                            <li>Le scout est simple, il a du respect pour lui-même et pour les autres.</li>
                        </ul>

                    </section>
                    <section className="section-staff">
                        <h2>Le staff</h2>
                        <ul className="list-item">
                            <li>Chaouis</li>
                            <li>Carcajou</li>
                            <li>Sotalia</li>
                            <li>Monax</li>
                            <li>Bassaris</li>
                            <li>Mathieu</li>
                            <li>Chousingha</li>
                        </ul>
                        <p>Pour toutes informations sur la chaumière veuillez nous contacter par mail<br />
                            mail: <a href="mailto:bierges.escurial@gmail.com"> bierges.escurial@gmail.com </a></p>
                    </section>
                    <section id="activity-desktop">
                        <h2>Les rendez-vous</h2>
                        <div className="activity">
                            <h3>Quand ?</h3>
                            <h3>Quoi ?</h3>
                            <h3>Où ?</h3>
                        </div>
                        {this.state.meetings.map((meeting, index) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <div className="activity">
                                    <p>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</p>
                                    <p>{meeting.libelle}</p>
                                    <p>{meeting.infos}</p>
                                </div>
                            )
                        })}
                    </section>
                    <section id="activity-mobile">
                        <h2>Les rendez-vous</h2>
                        {this.state.meetings.map((meeting) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <ul className="activity list-item">
                                    <li>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</li>
                                    <li>{meeting.libelle}</li>
                                    <li>{meeting.infos}</li>
                                </ul>
                            )
                        })}
                    </section>
                </main>

                <Footer />

            </>
        );
    }
}

export default MainScouts;
