import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";

/* -- FontAwsome -- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'
const trash = <FontAwesomeIcon icon={faTrashCan} />
const modify = <FontAwesomeIcon icon={faPenToSquare} />
const close = <FontAwesomeIcon icon={faXmark} />

class CardUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            unfilteredUsers: this.props.users,
            currentUserId : undefined,
            currentUserEmail : undefined,
            currentUserSection : undefined,
            currentUserRole : undefined,
            currentUserPwd : "*****",
            currentUserCheftain : undefined,
        };
        this.openPopupModify = this.openPopupModify.bind(this)
        this.closePopupModify = this.closePopupModify.bind(this)
        this.usersFilter = this.usersFilter.bind(this)
        this.handleChange = this.handleChange.bind(this) 
        this.handleModifyUserClick = this.handleModifyUserClick.bind(this)
    };

    usersFilter(event) {
        this.setState({ usersFilter: event.target.value })
        let filter = event.target.value.toUpperCase()
        console.log(filter)
        let newusers = this.state.unfilteredUsers.filter((user) => 
            user.email.toUpperCase().includes(filter) )
            //à optimiser
            //user.role.toUpperCase().includes(filter) || 
            // user.section.toUpperCase().includes(filter))
            //console.log(newusers.toLowerCase())
        this.props.updateUserList(newusers) 
    }

    openPopupModify(event) {
            this.setState({ PopupModifyUserOpen: true });
            let filter = event.target.closest('.containerItemAdmin').id
            let usr = this.props.users.filter((user) => user._id.includes(filter))[0]
            usr.password = "*****"
            this.setState({
                currentUserId : usr._id,
                currentUserEmail : usr.email,
                currentUserRole : usr.role,
                currentUserSection : usr.section,
                currentUserCheftain : usr.cheftain }) 
    }

    closePopupModify() {
        this.setState({ PopupModifyUserOpen: false });
    }

    deleteuser(id){
        if (!window.confirm('Are you sure to delete this chef ?')) {
            return;
        }
        axios.get(this.state.url + '/deleteUser/' + id, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            console.log(response)
            this.props.updateUsersList(this.props.users.filter((user) => !user._id.includes(id))) 
        }).catch(error => {
            console.log(error)
        }); 
        
    }

     handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
     }


    handleModifyUserClick(event) {
        this.setState({ PopupModifyUserOpen: false });
        axios.post(this.state.url + '/updateUser/', {
            _id: this.state.currentUserId,
            email: this.state.currentUserEmail,
            password: this.state.currentUserPwd,
            section: this.state.currentUserSection,
            role: this.state.currentUserRole
        }, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            console.log(response)
            //this.fetchData();
            alert("L'utilisateur a bien été modifié. Rechargez la page pour voir cette modification")
        }).catch(error => {
            console.log(error)
        });
    }


    render() {
        return (
            <>
                <section>
                    <h2>Liste des chefs</h2>
                    {/* <article className="filtre">
                        <input
                            type="text"
                            name="searchBar"
                            className="searchBar"
                            placeholder="Recherche le mail..."
                            onChange={this.usersFilter}
                        />
                    </article> */}
                    <article className="admin-card">
                        {this.props.users.map((user) => (
                            <div id={user._id} className="containerItemAdmin">
                                <p>{user.email}</p>
                                <p>{user.role ? user.role : "Chef"} - {user.section ? user.section : "PAS DE SECTION"}</p>
                                <div className="event">
                                    <div onClick={this.openPopupModify} isopen={this.state.PopupModifyUserOpen} className="icone modify">{modify}</div>
                                    <div onClick={e => {this.deleteuser(user._id)}} className="icone trash">{trash}</div>                                    
                                </div>
                            </div>
                        ))}
                    </article>
                </section>
                <section id={this.state.PopupModifyUserOpen ? 'modifyopen' : 'modifyclose'}>
                        <h3>Modifier les informations personnelles</h3>
                        <div className="form">
                            <div className="champ">
                                <label htmlFor="currentUserEmail">E-mail :</label>
                                <input type="email" name="currentUserEmail" id="currentUserEmail" value={this.state.currentUserEmail ? this.state.currentUserEmail : "Unhandeld error"} onChange={this.handleChange} />
                                <label htmlFor="currentUserPwd">Mot de passe :</label>
                                <input type="password" name="currentUserPwd" id="currentUserPwd" placeholder="*****" value={this.state.currentUserPwd ? this.state.currentUserPwd : "Unhandeld error"} onChange={this.handleChange} />
                                <label htmlFor="currentUserRole">Rôle : </label>
                                <select  name="currentUserRole" id="currentUserRole" onChange={this.handleChange}>
                                    <option selected value={this.state.currentUserRole ? this.state.currentUserRole : "CHEF"} >{this.state.currentUserRole ? this.state.currentUserRole : "CHEF"}</option>
                                    <option value="CU">CU</option>
                                    <option value="CHEF">CHEF</option>
                                    <option value="PARENT">PARENT</option>
                                </select>
                                <label htmlFor="currentUserSection">Section :</label>
                                <select name="currentUserSection" id="section" onChange={this.handleChange}>
                                    <option selected value={this.state.currentUserSection ? this.state.currentUserSection : "PAS DE SECTION"}  > {this.state.currentUserSection ? this.state.currentUserSection : "PAS DE SECTION"} </option>
                                    <option value="NULL" >PAS DE SECTION</option>
                                    <option value="NUTONS">NUTONS</option>
                                    <option value="LUTINS">LUTINS</option>
                                    <option value="LOUPS">LOUPS</option>
                                    <option value="GUIDES">GUIDES</option>
                                    <option value="SCOUTS">SCOUTS</option>
                                    <option value="HORIZONS">HORIZONS</option>
                                    <option value="ROUTE">ROUTE</option>
                                </select>
                            </div>
                            <div className="champ">
                                <br /><br /><br /><p className='text-center'><a type="submit" id="btn-subscribe" onClick={this.handleModifyUserClick}>Modifier</a></p>
                            </div>
                        </div>
                        <div onClick={this.closePopupModify} className="icone close">{close}</div>
                </section>
            </>
        );
    }
}
export default CardUser;