import React from "react";

import '../../assets/css/section.css'
import '../../assets/css/main.css'
import nuton from '../../assets/img/nutons.jpg'

// reactstrap components

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import axios from "axios"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTent, faTree, faBug, faSeedling, faSun, faFaceLaughBeam } from '@fortawesome/free-solid-svg-icons'


const tent = <FontAwesomeIcon icon={faTent} />
const tree = <FontAwesomeIcon icon={faTree} />
const bug = <FontAwesomeIcon icon={faBug} />
const seedling = <FontAwesomeIcon icon={faSeedling} />
const sun = <FontAwesomeIcon icon={faSun} />
const laught = <FontAwesomeIcon icon={faFaceLaughBeam} />

class MainNutons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetings: [],
            url: "https://bierges-40e-api.herokuapp.com"
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.state.url + '/fetchMeetings/NUTONS').then(response => {
            this.setState({ meetings: response.data.meetings, isLoading: false })
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
                <Navbar instagramLink="https://www.instagram.com/nutonsbierges/" />
                <section className="section-home home-nuton">
                    <h1>Les nutons</h1>
                    <p className="presentation-subtitle text-center"><em>Les messagers de la forêt</em></p>
                </section>
                <main>
                    <section className="intro-nuton">
                        <article>
                            <img src={nuton} alt="" />
                        </article>
                        <article>
                            <p>La section Nuton, appelée Chaumière, regroupe des enfants de 5 à 7 ans.</p>
                            <p>Au cours de l’année et du camp, les nutons apprennent à vivre avec les autres en jouant, chantant, bricolant… Ils sont aussi amenés à observer, découvrir le monde extérieur, communiquer avec les autres par le biais d’activités ludiques.</p>
                        </article>
                    </section>
                    <section>
                        <h2>Être nuton c'est...</h2>
                        <h3>Faire ses premiers pas vers l’autonomie et s’ouvrir petit à petit aux autres. Comme le dit leur devise, les nutons deviennent l’ami de tous !</h3>
                        <p>Au sein de la Chaumière, l’année est rythmée par 6 « Messages de la forêt. Ces 6 phrases clés correspondent à la progression du nuton. Elles transmettent également certaines valeurs et comportements.</p>
                        <article>
                            <figure className="background-white">
                                <div className="icone-section">{tent}</div>
                                <h4>Accueille comme la Chaumière</h4>
                                <p> Le nuton apprend l’accueil, la vie en groupe et la communication.
                                    Avec l’aide des chefs, il se crée une place au sein du groupe.</p>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section">{tree}</div>
                                <h4>Tiens bon comme l’arbre</h4>
                                <p>L’enfant découvre la persévérance, il sera encouragé à s’adapter à des situations nouvelles.</p>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section">{bug}</div>
                                <h4>Prépare-toi comme la nature</h4>
                                <p>Avec le soutien du staff, le nuton apprend des nouvelles choses qui lui seront utiles plus tard. Petit à petit il découvre ses capacités lui permettant de mieux se connaître.</p>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section">{seedling}</div>
                                <h4>Ouvre-toi comme la fleur</h4>
                                <p>La Chaumière nuton part à la découverte des autres et de la nature par l’écoute,
                                    l’expression et la communication. L’enfant fait le passage du «moi» vers le «toi et moi» afin d’arriver tout doucement à la notion de groupe.</p>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section">{laught}</div>
                                <h4>Sois plein de vie comme la cascade</h4>
                                <p> Les nutons sont vifs et plein d’entrain. Les chefs les invitent à utiliser cette vivacité dans les jeux.</p>
                            </figure>
                            <figure className="background-white">
                                <div className="icone-section">{sun}</div>
                                <h4>Rayonne comme le soleil</h4>
                                <p>Pour terminer l’année nuton, les enfants font l’expérience de la vie communautaire lors du camp. C’est le temps de l’aventure et de la vie en groupe.</p>
                            </figure>
                        </article>
                    </section>
                    <section className="section-staff">
                        <h2>Le staff</h2>
                        <ul className="list-item">
                            <li>Kinkajou</li>
                            <li>Fusca</li>
                            <li>Mederic</li>
                            <li>Sapajou</li>
                        </ul>
                        <p>Pour toutes informations sur la chaumière veuillez nous contacter par mail<br />
                            mail: <a href="mailto:nutonsbierges@gmail.com">nutonsbierges@gmail.com  </a></p>
                    </section>
                    <section id="activity-desktop">
                        <h2>Les rendez-vous</h2>
                        <div className="activity">
                            <h3>Quand ?</h3>
                            <h3>Quoi ?</h3>
                            <h3>Où ?</h3>
                        </div>
                        {this.state.meetings.map((meeting, index) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <div className="activity">
                                    <p>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</p>
                                    <p>{meeting.libelle}</p>
                                    <p>{meeting.infos}</p>
                                </div>
                            )
                        })}
                    </section>
                    <section id="activity-mobile">
                        <h2>Les rendez-vous</h2>
                        {this.state.meetings.map((meeting) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <ul className="activity list-item">
                                    <li>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</li>
                                    <li>{meeting.libelle}</li>
                                    <li>{meeting.infos}</li>
                                </ul>
                            )
                        })}
                    </section>
                </main>
                <Footer />

            </>
        );
    }
}

export default MainNutons;
