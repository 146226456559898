import React from "react";

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import axios from "axios"
import pi from '../../assets/img/pis.jpg'

class MainPionnier extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetings: [],
            url: "https://bierges-40e-api.herokuapp.com"
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.state.url + '/fetchMeetings/HORIZONS').then(response => {
            this.setState({ meetings: response.data.meetings, isLoading: false })
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
                <Navbar instagramLink="https://www.instagram.com/horizonsbierges/" />
                <section className="section-home home-pi">
                    <h1>Les horizons</h1>
                    <p className="presentation-subtitle text-center"><em>Le lien de la chaîne</em></p>
                </section>
                <main>
                    <section className="intro-nuton">
                        <article>
                            <img src={pi} alt="" />
                        </article>
                        <article>
                            <p> La Chaîne Horizons s’adresse au plus de 17 ans..</p>
                            <p> Le maillon est le symbole de la place et de l’importance que chaque horizons a au sein de la Chaîne.<br />
                                Être horizons, c’est relever des défis. Au sein du groupe, les ados prennent conscience de leur place dans l’Unité et de la société. Ensemble, ils réfléchissent sur leur rôle et les actions qu'ils veulent entreprendre.</p>
                        </article>
                    </section>
                    <section>
                        <p>Au fil de l’année, ils choisissent, préparent, réalisent et évaluent un projet de camp. Celui-ci peu être de type social (animation de jeunes dans un village plus démuni, …), international (partir à la rencontre d’autres scouts dans le monde, …), culturel ou encore de type chantier (proposer son aide dans la reconstruction d’un village, …).</p>
                        <p> Le défi des horizons est donc de se lancer en équipe dans un grand projet qui a pour objectif de servir et de se brancher sur le monde extérieur.</p>
                    </section>
                    <section className="section-staff">
                        <h2>Le staff</h2>
                        <ul className="list-item">
                            <li>Chaouis</li>
                            <li>Urso</li>
                            <li>Wallaby</li>
                            <li>Monax</li>
                            <li>Chinkara</li>
                            <li>Impala</li>
                            <li>Simensis</li>
                        </ul>
                        <p>Pour toutes informations sur la chaumière veuillez nous contacter par mail<br />
                            mail: <a href="mailto:horizons.bierges@gmail.com"> horizons.bierges@gmail.com </a></p>
                    </section>
                    <section id="activity-desktop">
                        <h2>Les rendez-vous</h2>
                        <div className="activity">
                            <h3>Quand ?</h3>
                            <h3>Quoi ?</h3>
                            <h3>Où ?</h3>
                        </div>
                        {this.state.meetings.map((meeting, index) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <div className="activity">
                                    <p>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</p>
                                    <p>{meeting.libelle}</p>
                                    <p>{meeting.infos}</p>
                                </div>
                            )
                        })}
                    </section>
                    <section id="activity-mobile">
                        <h2>Les rendez-vous</h2>
                        {this.state.meetings.map((meeting) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <ul className="activity list-item">
                                    <li>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</li>
                                    <li>{meeting.libelle}</li>
                                    <li>{meeting.infos}</li>
                                </ul>
                            )
                        })}
                    </section>
                </main>
                <Footer />
            </>
        );
    }
}

export default MainPionnier;
