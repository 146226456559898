import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/main.css";
// pages
import Index from "views/Index.js";
import MainPionnier from "views/pionniers/MainPionnier";
import MainNutons from "views/nutons/MainNutons";
import MainLutins from 'views/lutins/MainLutins'
import MainLoups from 'views/loups/MainLoups'
import MainGuides from "views/guides/MainGuides"
import MainScouts from "views/scouts/MainScouts"
import Subscribe from './components/Subscribe'
import Admin from './views/admin/admin'
import Login from './views/login/login'
import Blog from './views/blog/Blog'
import Chef from './views/chef/chef'
import MentionLegale from './views/MentionLegale/MentionLegale'

ReactDOM.render(

  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/pionniers"
        render={props => <MainPionnier {...props} />}
      />
      <Route
        exact
        path="/nutons"
        render={props => <MainNutons {...props} />}
      />
      <Route
        exact
        path="/lutins"
        render={props => <MainLutins {...props} />}
      />
      <Route
        exact
        path="/loups"
        render={props => <MainLoups {...props} />}
      />
      <Route
        exact
        path="/guides"
        render={props => <MainGuides {...props} />}
      />
      <Route
        exact
        path="/scouts"
        render={props => <MainScouts {...props} />}
      />
      <Route
        exact
        path="/subscribe"
        render={props => <Subscribe {...props} />}
      />
      <Route
        exact
        path="/admin"
        render={props => <Admin {...props} />}
      />

      <Route
        exact
        path="/chef"
        render={props => <Chef {...props} />}
      />

      <Route
        exact
        path="/login"
        render={props => <Login {...props} />}
      />

      <Route
        exact
        path="/blog"
        render={props => <Blog {...props} />}
      />

<Route
        exact
        path="/mention-legale"
        render={props => <MentionLegale {...props} />}
      />
      <Route path="/index" render={props => <Index {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>
  ,
  document.getElementById("root")
);
