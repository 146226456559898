import React from "react";

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import CardArticle from "../../components/CardArticle.jsx";
import '../../assets/css/blog.css'
import '../../assets/css/main.css'
import axios from "axios"



class Mentionlegale extends React.Component {

    render() {
        return (
            <>
                <Navbar facebookLink="https://www.facebook.com/groups/1290833304584351/" />
                <section className="section-home home-subscribe">
                    <h1>Mentions légales</h1>
                </section>
                <main>
                    <section>
                        <h2>Mentions légales</h2>
                        <h3>Éditeur responsable</h3>
                        <p>Les éditeurs responsables du site www.40iemebierges.be sont les chefs d’unité. Rue Saint-Pierre 8 à Bierges (derrière l’église).</p>
                        <h3>Propriété intellectuelle</h3>
                        <p>Toutes les illustrations et tous les supports (photos, vidéos, logos, textes, documents pdf,...) présents sur le site sont la propriété de l’unité Saint Pierre et Marcellin et ne peuvent être copiés, reproduits ou stockés de toute autre manière. Certains supports sont également libre de droit. L’impression de pages du site www.40iemebierges.be est autorisée à des fins d'usage personnel ou pour les activités liées au mouvement.</p>
                    </section>
                    <section>
                        <h2>Protection de données</h2>
                        <p>En remplissant les formulaires du site, vous donnez à l’asbl de Saint Pierre et Marcellin l'autorisation de traiter vos données à caractère personnel comme exposé dans ce règlement sur le respect de la vie privée. La unité Saint Pierre et Marcellin de Bierges utilise certaines données dans le cadre stricte de l’activité de l’unité. Les membres de la 40e sont liés à la GCB, les Guides Catholiques de Belgique.</p>
                    </section>
                    <section>


                        <h2>Photographie</h2>
                        <p>Chaque début d’année, les responsables de section donne la possibilité aux parents d’interdire que l’asbl prennent leur(s) enfants(s) en photo. Les chefs d’unité et le webmaster font leur possible pour respecter cela. Les photos présentes sur ce site respectent les règles sanitaires en vigueur au moment où elles ont été prises.
                        </p>
                    </section>
                </main>
                <Footer />

            </>
        );
    }
}

export default Mentionlegale;
