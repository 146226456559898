import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";


class AddChildren extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            nom :"",
            prenom: "",
            section: "",
            datenaiss: "",
            email: "",
            commentaire: ""
        };

        this.handleNewMeetingClick = this.handleNewMeetingClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

   handleNewMeetingClick(event) {
        axios.post(this.state.url + '/createChildren', {
            datenaiss: this.state.datenaiss,
            nom: this.state.nom,
            prenom: this.state.prenom,
            section : this.state.section,
            commentaire: this.state.commentaire,
            email: this.state.email
        }, {
            headers: {
                token: this.props.token
            }
        }).then(response => { 
            alert("L'animé a bien été ajouté")
        }).catch(error => {
            console.log(error)
            alert("Une erreur a été detectée, veuillez contactez le webmaster")
        });
        
    } 

    render() {
        return (
            <>
                <section>
                    <h2>Ajouter un animé :</h2>
                    <div className="form">
                        <div className="champ">
                            <label htmlFor="nom">Nom </label>
                            <input type="text" name="nom" id="nom" onChange={this.handleChange} required/>
                            <label htmlFor="prenom">Prénom</label>
                            <input type="text" name="prenom" id="prenom" onChange={this.handleChange} required/>
                            <label htmlFor="datenaiss">Date de naissance</label>
                            <input type="date" name="datenaiss" id="datenaiss" onChange={this.handleChange} required/>
                            <label htmlFor="email">Email :</label>
                            <input type="email" name="email" id="email" onChange={this.handleChange} required/>
                            <label htmlFor="commentaire">Commentaire :</label>
                            <input type="text" name="commentaire" id="commentaire" onChange={this.handleChange} required/>
                            <label htmlFor="section" >Section : </label>
                            <select name="section" id="section" onChange={this.handleChange} required>
                                <option selected value="NUTONS">NUTONS</option>
                                <option value="LUTINS">LUTINS</option>
                                <option value="LOUPS">LOUPS</option>
                                <option value="GUIDES">GUIDES</option>
                                <option value="SCOUTS">SCOUTS</option>
                                <option value="HORIZONS">HORIZONS</option>
                                <option value="ROUTE">ROUTE</option>
                            </select>
                        </div>
                        
                        <div className="champ">
                            <br /><br /><br /><p className='text-center'><a type="submit" id="btn-subscribe" onClick={this.handleNewMeetingClick}>Créer</a></p>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}

export default AddChildren;