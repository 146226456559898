import React from "react";
import { useState } from 'react';
// nodejs library that concatenates strings
import Hamburger from "./Hamburger";
import ficheSante from '../../assets/files/fiche_sante.pdf'
import autorisationParentale from '../../assets/files/autorisation_parentale.pdf'
import droitImage from '../../assets/files/droit_a_image.pdf'
import uniformeGCB from '../../assets/files/Uniforme-Guide.pdf'
import carnetParents from '../../assets/files/Carnet des parents.pdf'
import '../../assets/css/main.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket, faDownload } from '@fortawesome/free-solid-svg-icons'

const login = <FontAwesomeIcon icon={faRightToBracket} />
const download = <FontAwesomeIcon icon={faDownload} />


function GuidesNavbar() {

  const [logo, setLogo] = useState("NewLogoInvert.png");
  const [facebook, setFacebook] = useState("Facebook.svg");
  const [instagram, setInstagram] = useState("Instagram.svg");
  const [menuSections, setMenuSections] = useState("sectionclose");
  const [menuAdmin, setMenuAdmin] = useState("adminclose");

  window.addEventListener("scroll", function () {
    let navArea = document.getElementById("navArea");

    if (window.pageYOffset > 120) {
      navArea.classList.add("is-sticky");
      document.getElementById("slogan").style.color = "grey";
      setLogo("NewLogo.png");
      setFacebook("Facebook-gray.svg");
      setInstagram("Instagram-gray.svg");
    } else {
     navArea.classList.remove("is-sticky");
      document.getElementById("slogan").style.color = "white";
      setLogo("NewLogoInvert.png");
      setFacebook("Facebook.svg");
      setInstagram("Instagram.svg");
    }
  });

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
    setFacebook("Facebook-gray.svg");
      setInstagram("Instagram-gray.svg");
  }

  const toggleSection = () => {
    menuSections === "sectionclose" ? setMenuSections("sectionopen") : setMenuSections("sectionclose");
    setMenuAdmin("adminclose");
  }

  const toggleAdmin = () => {
    menuAdmin === "adminclose" ? setMenuAdmin("adminopen") : setMenuAdmin("adminclose");
    setMenuSections("sectionclose");
  }


  return (
    <header id="navArea" className="standard">
      <figure>
        <a href="/">
          <img alt="" src={require("assets/img/" + logo)} width="125" eight="auto"></img>
          <p id="slogan">Notre unité, votre unité</p>
        </a>
      </figure>
      <div className="hamburgerbox" onClick={toggleHamburger}>
        <Hamburger isOpen={hamburgerOpen} />
      </div>
      <ul id={hamburgerOpen ? 'navopen' : 'navclose'}>
        <li><a href="/subscribe" title="L'endroit idéal pour votre enfant" className="first-link">Inscription</a></li>
        <li className="deroulant"><a  onClick={toggleSection}>Les Sections</a>
          <ul  className="sous" id={menuSections}>
            <li><a href="/nutons" title="La découverte du monde" className="sous-link">Les nutons</a></li>
            <li><a href="/lutins" title="Le développement personnel" className="sous-link">Les lutins</a></li>
            <li><a href="/loups" title="Le développement personnel" className="sous-link">Les loups</a></li>
            <li><a href="/guides" title="La prise de responsabilités" className="sous-link">Les aventures</a></li>
            <li><a href="/scouts" title="La prise de responsabilités" className="sous-link">Les aventuriers</a></li>
            <li><a href="/pionniers" title="Le lien de la chaîne" className="sous-link">Les horizons</a></li>
          </ul>
        </li>
        <li><a href="https://40iemebierges.weebly.com/" target="_blank" title="Les photos de vos enfants" className="first-link">Photos</a></li>
        <li ><a href="/blog">Blog</a></li>
        <li className="deroulant"><a onClick={toggleAdmin}>Administratif</a>
          <ul className="sous downlaod" id={menuAdmin}>
            <li><a href={ficheSante} target="_blank" className="sous-link"><div className="icone">{download}</div> Fiche santé</a></li>
            <li><a href={droitImage} target="_blank" className="sous-link"><div className="icone">{download}</div> Droit à l'image</a></li>
            <li><a href={uniformeGCB} target="_blank" className="sous-link"><div className="icone">{download}</div> Uniformes GCB</a></li>
            <li><a href={autorisationParentale} target="_blank" className="sous-link"><div className="icone">{download}</div> Autorisation parentale</a></li>
            <li><a href={carnetParents} target="_blank" className="sous-link"><div className="icone">{download}</div> Carnet des parents</a></li>
          </ul></li>
        <li className="nav-reseau">
          <a><img className="picto-reseau" src={require("assets/img/svg/" + facebook)} /></a>
          <a><img className="picto-reseau" src={require("assets/img/svg/" + instagram)} /></a>
          <a href="/login"><div className="icone">{login}</div></a>
          </li>
        
        </ul>

    </header>
  );
}

export default GuidesNavbar;