import React from "react";

// reactstrap components
import '../assets/css/main.css'

function ValeursCards() {
    return (
        <article>
            <figure>
                <div className="image-background"></div>
                <p>Respect</p>
            </figure>
            <figure>
                <div className="image-background"></div>
                <p>Entraide</p>
            </figure>
            <figure>
                <div className="image-background"></div>
                <p>Proactivité</p>
            </figure>
        </article>
    );
}

export default ValeursCards;


