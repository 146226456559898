import React from "react";

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import lutin from '../../assets/img/loups.jpg'
import '../../assets/css/section.css'
import '../../assets/css/main.css'
import axios from "axios"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMound, faWater, faFire, faWind, faPeopleLine, faHandHoldingHeart, faFaceLaughBeam, faCircleCheck } from '@fortawesome/free-solid-svg-icons'


const mound = <FontAwesomeIcon icon={faMound} />
const water = <FontAwesomeIcon icon={faWater} />
const fire = <FontAwesomeIcon icon={faFire} />
const wind = <FontAwesomeIcon icon={faWind} />
const people = <FontAwesomeIcon icon={faPeopleLine} />
const hand = <FontAwesomeIcon icon={faHandHoldingHeart} />
const laught = <FontAwesomeIcon icon={faFaceLaughBeam} />
const check = <FontAwesomeIcon icon={faCircleCheck} />

class MainLutins extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetings: [],
            url: "https://bierges-40e-api.herokuapp.com"
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.state.url + '/fetchMeetings/LUTINS').then(response => {
            this.setState({ meetings: response.data.meetings, isLoading: false })
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
                <Navbar facebookLink="https://www.facebook.com/groups/1290833304584351/" />
                <section className="section-home home-lutin">
                    <h1>Les lutins</h1>
                    <p className="presentation-subtitle text-center"><em>Le développement personnel</em></p>
                </section>
                <main>
                    <section className="intro-nuton">
                        <article>
                            <img src={lutin} alt="" />
                        </article>
                        <article>
                            <p>La Ronde Lutin accueille des filles de 8 à 12 ans.</p>
                            <p>Elle se compose de sizaines (équipes de 6 – 8 enfants) où chaque enfant à ses responsabilités
                                et apporte aux autres les richesses de sa propre évolution.</p>
                        </article>
                    </section>
                    <section>
                        <h2>Être lutin c'est...</h2>
                        <h3>Développer ses aptitudes avec et par les autres.</h3>
                        <p>Au sein de la ronde, les enfants découvrent les richesses de chacun grâce à la vie en petits groupes.
                            Ils ont de petites responsabilités, mettent leurs compétences aux services des autres et prennent conscience
                            de leurs limites.</p>
                        <p>La devise lutin, "De notre mieux", est une forme d’aide, d’encouragement, de confiance,
                            mais également un signe d’amitié qui permet aux lutins de persévérer dans l’effort.</p>
                        <p> Durant 4 années, les enfants cheminent sur des sentiers. Ceux-ci représentent le rythme de leur évolution
                            et l’aide dans leur progression personnelle.</p>
                        <p>Les lutins apprennent à être :</p>
                        <article>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{mound}</div>
                                <h4>Accueillant comme la terre</h4>
                            </figure>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{water}</div>
                                <h4>Actif comme l’eau</h4>
                            </figure>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{fire}</div>
                                <h4>Généreux comme le feu</h4>
                            </figure>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{wind}</div>
                                <h4>Responsable comme l’air</h4>
                            </figure>
                        </article>
                        <p>Les Règles d’Or, 4 thèmes importants dans la vie lutin, permettent aux enfants de vivre
                            selon certaines valeurs :</p>
                        <article>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{people}</div>
                                <h4>Un lutin pense d’abord aux autres</h4>
                            </figure>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{hand}</div>
                                <h4>Un lutin apprend à faire plaisir aux autres</h4>
                            </figure>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{laught}</div>
                                <h4>Un lutin sème la joie</h4>
                            </figure>
                            <figure className="four background-white">
                                <div className="icone-section icone-loup">{check}</div>
                                <h4>Un lutin est toujours vrai</h4>
                            </figure>
                        </article>
                    </section>
                    <section className="section-staff">
                        <h2>Le staff</h2>
                        <ul className="list-item">
                            <li>Lemming</li>
                            <li>Akita</li>
                            <li>Fennec</li>
                            <li>Nagor</li>
                            <li>Tanuki</li>
                            <li>Irbis</li>
                        </ul>
                        <p>Pour toutes informations sur la chaumière veuillez nous contacter par mail<br />
                            mail: <a href="mailto:lutins.bierges@gmail.com"> lutins.bierges@gmail.com </a></p>
                    </section>
                    <section id="activity-desktop">
                        <h2>Les rendez-vous</h2>
                        <div className="activity">
                            <h3>Quand ?</h3>
                            <h3>Quoi ?</h3>
                            <h3>Où ?</h3>
                        </div>
                        {this.state.meetings.map((meeting, index) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <div className="activity">
                                    <p>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</p>
                                    <p>{meeting.libelle}</p>
                                    <p>{meeting.infos}</p>
                                </div>
                            )
                        })}
                    </section>
                    <section id="activity-mobile">
                        <h2>Les rendez-vous</h2>
                        {this.state.meetings.map((meeting) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <ul className="activity list-item">
                                    <li>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</li>
                                    <li>{meeting.libelle}</li>
                                    <li>{meeting.infos}</li>
                                </ul>
                            )
                        })}
                    </section>
                </main>
                <Footer />

            </>
        );
    }
}

export default MainLutins;
