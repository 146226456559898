import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUser, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
const pictoDate = <FontAwesomeIcon icon={faCalendar} />
const pictoUser = <FontAwesomeIcon icon={faUser} />
const pictoX = <FontAwesomeIcon icon={faCircleXmark} />
const pictoV = <FontAwesomeIcon icon={faCircleCheck} />

class CardArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            id:"",
            titre:"",
            text:"",
            auteur:"",
            date:"",
            validated:false
        };
        this.validatedArticles = this.validatedArticles.bind(this)
        this.notValidateArticle = this.notValidateArticle.bind(this)
    };

    validatedArticles(event) {
        let id = event.target.closest('.containerItemAdmin').id
        axios.post(this.state.url + '/validatedArticles', {
            _id: id
        }, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            this.props.updateArticlesList(this.props.blogarticles.filter((article) => !article._id.includes(id))) 
            alert("L'article a bien été validé.")
        }).catch(error => {
            console.log(error)
            alert("Erreur")
        }); 
    }

    notValidateArticle(id) {
        if (!window.confirm('Are you sure to delete this article ?')) {
            return;
        }
        axios.get(this.state.url + '/deleteArticles/' + id, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            console.log(response)
            this.props.updateArticlesList(this.props.blogarticles.filter((article) => !article._id.includes(id))) 
            alert("L'article a bien été supprimé.")
        }).catch(error => {
            console.log(error)
        }); 
    }


    render() {
        return (
            <>
                <section>
                    <h2>Article en attente</h2>
                </section>
                <section>
                    <article className="blog-article admin-card">
                        {this.props.blogarticles.map((article, index) => {
                            let dateNumbers = article.date.split('T')[0].split('-')
                            if (article.validated === false) {
                                return (
                                    <div className="containerItemAdmin" id={article._id}>
                                        <h3>{article.titre}</h3>
                                        <p>{article.text}</p>
                                        <ul className="infos-pratique">
                                            <li><div className="icone">{pictoDate}</div>{dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}  </li>
                                            <li><div className="icone">{pictoUser}</div>{article.auteur} </li>
                                        </ul>
                                        <div className="toValidated">
                                            <div onClick={this.validatedArticles} className="icone validated">{pictoV}</div>
                                            <div onClick={e => {this.notValidateArticle(article._id)}} className="icone not-validated">{pictoX}</div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </article>
                </section>
            </>
        );
    }
}

export default CardArticle;