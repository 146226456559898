import React from "react";
import cloud from '../../assets/img/clouds.png';



function MainHeader() {
  return (
    <>
      <section className="homepage">
      <div id="cloud"><img alt="cloud"src={cloud}/></div>
        <h1 id="main-title">La 40e <br/><em>de Bierges</em></h1>
        <p className="presentation-subtitle text-center"><em>
          L’Unité de la 40e de Bierges, une école de vie qui pousse les jeunes en dehors de leur zone de confort où ils vivront leurs meilleurs souvenirs de jeunesse !
        </em></p>
      </section>
      
    </>
  );
}

export default MainHeader;
