import React from "react";

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import axios from "axios"
import { Redirect } from "react-router-dom";
import tools from "../../toolBox"

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectingCU: false,
            redirectingChef: false,
            email: "",
            password: "",
            url: "https://bierges-40e-api.herokuapp.com",
        };
        this.handleConnectionClick = this.handleConnectionClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    };

    componentDidMount() {
        if (tools.checkIfConnected() ) {
            this.setState({ redirectingCU: true })
            this.setState({ redirectingChef: true })
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleConnectionClick() {
        console.log(this.state)
        if (this.state.email === '' || this.state.password === '') {
             alert('Please fill in all fields of the form')
             return;
         }
         if (!/\S+@\S+\.\S+/.test(this.state.email)) {
             alert('The mail does not correspond to the right format')
             return;
         }
         axios.post(this.state.url + "/connection", {
             email: this.state.email,
             password: this.state.password
         }).then((response) => {
             if (response.status === 200) {
                 let d = new Date();
                 d.setTime(d.getTime() + (3 * 24 * 60 * 60 * 1000));
                 let expires = "expires=" + d.toUTCString();
                 document.cookie = "Token=" + response.data.token + ";" + expires + ";path=/"
                 if (response.data.role === "CU") {
                     this.setState({ redirectingCU: true })
                     console.log("CU")
                 } else if (response.data.role === "CHEF") {
                     this.setState({ redirectingChef: true })
                     console.log("Chef")
                 }
             }
         }).catch((error) => {
             alert(error.response.data)
         });
     }



   /* à optimiser 
   handlePwdForget() {
        axios.post(this.state.url + '/pwdForget', {
            email: this.state.email
        }).then(response => {
            alert("Le mail a bien été envoyé.")
            console.log(response)
            //this.fetchData();
        }).catch(error => {
            console.log(error)
            alert("Une erreure est détectée, contactez le webmaster")
        });
    } */

    render() {
        if (this.state.redirectingCU) return <Redirect to="/admin" />
        if (this.state.redirectingChef) return <Redirect to="/chef" />
        return (
            <>
                <Navbar/>
                <section className=" section-home home-subscribe">
                    <h1>Connexion</h1>
                </section>
                <main>
                    <section>
                        <form className="form">
                            <div className="champ">
                                <label for="email">E-mail : </label>
                                <input type="text" name="email" id="email" onChange={this.handleChange} />
                                <label for="email">Mot de passe : </label>
                                <input type="password" name="password" id="password" onChange={this.handleChange} />
                            </div><br/>
                            <p>Mot de passe oublié ? <a href="mailto:40bierges@gmail.com" className="btn-text">Envoyer un mail au chef d'unité</a></p>
                            <div className="champ">
                                <br /><br /><br /><p onClick={this.handleConnectionClick} className='text-center'><a type="submit" id="btn-subscribe">Connexion</a></p>
                            </div>
                        </form>
                    </section>
                </main>
                <Footer/>
            </>
        );
    }
}

export default Login;