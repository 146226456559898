import React from "react";

import ficheSante from '../../assets/files/fiche_sante.pdf'
import autorisationParentale from '../../assets/files/autorisation_parentale.pdf'
import droitImage from '../../assets/files/droit_a_image.pdf'
import declarationAccident from "../../assets/files/Déclaration_d'accident_2020-2021.doc"
import declarationSinistre from '../../assets/files/Déclaration_de_sinistre.doc'

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const download = <FontAwesomeIcon icon={faDownload} />


class DocumentChef extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
        };

    };


    render() {
        return (
            <>
                <section>
                    <h2>Quelques documents pratiques</h2>
                    <ul className="list-item doc">
                    <li><a href={declarationAccident} target="_blank" ><div className="icone">{download}</div> Déclaration d'accident</a></li>
                    <li><a href={declarationSinistre} target="_blank" ><div className="icone">{download}</div> Déclaration de sinistre</a></li>
                    <li><a href={ficheSante} target="_blank" ><div className="icone">{download}</div> Fiche santé</a></li>
                    <li><a href={droitImage} target="_blank" ><div className="icone">{download}</div> Droit à l'image</a></li>
                    <li><a href={autorisationParentale} target="_blank" ><div className="icone">{download}</div> Autorisation parentale</a></li>
                    </ul>

                </section>
            </>
        );
    }
}
export default DocumentChef;