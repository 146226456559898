import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";

/* -- FontAwsome -- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'
const trash = <FontAwesomeIcon icon={faTrashCan} />
const modify = <FontAwesomeIcon icon={faPenToSquare} />
const close = <FontAwesomeIcon icon={faXmark} />

class CardMeeting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            unfilteredMeetings: this.props.meetings,
            currentMeeting : undefined,
            currentMeetingId : undefined,
            currentMeetingInfos : undefined,
            currentMeetingDate : undefined,
            currentMeetingSection : undefined,
            currentMeetingLibelle : undefined
        };

        this.meetingsFilter = this.meetingsFilter.bind(this)
        this.openPopupModify = this.openPopupModify.bind(this)
        this.closePopupModify = this.closePopupModify.bind(this)
        this.handleModifyMeetingClick = this.handleModifyMeetingClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
    };


    meetingsFilter(event) {
        this.setState({ meetingsFilter: event.target.value })
        let filter = event.target.value.toUpperCase()
        let newmeetings = this.state.unfilteredMeetings.filter((meeting) => 
        meeting.date.toUpperCase().includes(filter) ||
        meeting.libelle.toUpperCase().includes(filter) ||
        meeting.section.toUpperCase().includes(filter) ||
        meeting.infos.toUpperCase().includes(filter))
        this.props.updateMeetingList(newmeetings)
    }

    openPopupModify(event) {
            this.setState({ PopupModifyMeetingOpen: true });
            let filter = event.target.closest(".containerItemAdmin").id;
            let mtg = this.props.meetings.filter((meeting) => meeting._id.includes(filter))[0]
            mtg.password = "*****"
            mtg.date = mtg.date.split('T')[0].split('-')
            mtg.date = mtg.date[0]+"-"+mtg.date[1]+"-"+mtg.date[2]
            this.setState({
                currentMeetingId : mtg._id,
                currentMeetingInfos : mtg.infos,
                currentMeetingDate : mtg.date,
                currentMeetingSection : mtg.section,
                currentMeetingLibelle : mtg.libelle }) 
    }

    closePopupModify() {
        this.setState({ PopupModifyMeetingOpen: false });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
     }

    deleteMeeting(id){
        if (!window.confirm('Are you sure to delete this meeting ?')) {
            return;
        }
        axios.get(this.state.url + '/deleteMeeting/' + id, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            alert("La réunion a bien été suprimée")
            this.props.updateMeetingList(this.props.meetings.filter((meeting) => !meeting._id.includes(id))) 
        }).catch(error => {
            console.log(error)
        });
    }

    handleModifyMeetingClick(event) {
        this.setState({ PopupModifyMeetingOpen: false });
        axios.post(this.state.url + '/updateMeeting' , {
            _id: this.state.currentMeetingId,
            infos: this.state.currentMeetingInfos,
            date: this.state.currentMeetingDate,
            sectionMeeting: this.state.currentMeetingSection,
            libelle: this.state.currentMeetingLibelle
        }, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            alert("La réunion a bien été modifié")
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
            <section>
                        <h2>Liste des réunions</h2>
                        <article className="filtre">
                            <input
                                type="text"
                                name="searchBar"
                                className="searchBar"
                                placeholder="Recherche..."
                                onChange={this.meetingsFilter}
                            />
                        </article>
                        <article className="admin-card reunion">
                            {this.props.meetings.map((meeting, index) => {
                                let dateNumbers = meeting.date.split('T')[0].split('-')
                                return (
                                    <div className="containerItemAdmin" id={meeting._id}>
                                        <h3>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</h3>
                                        <p>{meeting.section}</p>
                                        <p>{meeting.libelle}</p>
                                        <p>{meeting.infos}</p>
                                        <div className="event">
                                            <div onClick={this.openPopupModify} isopen={this.state.PopupModifyMeetingOpen} className="icone modify">{modify}</div>
                                            <div onClick={e => {this.deleteMeeting(meeting._id)}} className="icone trash">{trash}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </article>
                    </section>
                    <section id={this.state.PopupModifyMeetingOpen ? 'modifyopen' : 'modifyclose'}>
                        <h2>Modifier les informations de la réunion</h2>
                        <div className="form">
                            <div className="champ">
                                <label htmlFor="currentMeetingDate">Date </label>
                                <input type="date" name="currentMeetingDate" id="currentMeetingDate" value={this.state.currentMeetingDate ? this.state.currentMeetingDate: "Unhandeld error"} onChange={this.handleChange} />
                                <label htmlFor="currentMeetingInfos">Heure et lieu</label>
                                <input type="text" name="currentMeetingInfos" id="currentMeetingInfos" value={this.state.currentMeetingInfos ? this.state.currentMeetingInfos : "Unhandeld error"} onChange={this.handleChange} />
                                <label htmlFor="currentMeetingLibelle">Libellé</label>
                                <input type="text" name="currentMeetingLibelle" id="currentMeetingLibelle" value={this.state.currentMeetingLibelle ? this.state.currentMeetingLibelle : "Unhandeld error"}  onChange={this.handleChange} />
                                <label htmlFor="currentMeetingSection">Section : </label>
                                <select name="currentMeetingSection" id="currentMeetingSection" onChange={this.handleChange}>
                                    <option selected value={this.state.currentMeetingSection ? this.state.currentMeetingSection : "Unhandeld error"} >{this.state.currentMeetingSection ? this.state.currentMeetingSection : "Unhandeld error"} </option>
                                    <option value="CHEFS" >ACTIVITE CHEF</option>
                                    <option value="NUTONS">NUTONS</option>
                                    <option value="LUTINS">LUTINS</option>
                                    <option value="LOUPS">LOUPS</option>
                                    <option value="GUIDES">GUIDES</option>
                                    <option value="SCOUTS">SCOUTS</option>
                                    <option value="HORIZONS">HORIZONS</option>
                                    <option value="ROUTE">ROUTE</option>
                                </select>
                            </div>
                            <div className="champ">
                                <br /><br /><br /><p className='text-center'><a type="submit" id="btn-subscribe" href="" onClick={this.handleModifyMeetingClick}>Modifier</a></p>
                            </div>
                        </div>
                        <div onClick={this.closePopupModify} className="icone close">{close}</div>
                    </section>
            </>
        );
    }
}
export default CardMeeting;