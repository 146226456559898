import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";

class AddArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            titre:"",
            auteur:"",
            date:"",
            text:""
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleNewArticleClick = this.handleNewArticleClick.bind(this)

    };

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleNewArticleClick() {
        axios.post(this.state.url + '/createBlogArticles', {
            titre: this.state.titre,
            auteur: this.state.auteur,
            date: this.state.date,
            text: this.state.text
        }).then(response => {
            console.log(response)
            alert("L'article a bien été ajouté")
        }).catch(error => {
            console.log(error)
            alert("Une erreur a été detectée, veuillez contactez le webmaster")
        });
    }


    render() {
        return (
            <>
                <section>
                    <h2>Écriture d'un article</h2>
                    <div className="form">
                        <div className="champ">
                            <label for="auteur">Auteur</label>
                            <input required type="text" name="auteur" id="auteur" onChange={this.handleChange} />
                            <label for="date">Date</label>
                            <input type="date" name="date" id="date" onChange={this.handleChange} />
                            <label for="titre">Titre</label>
                            <input required type="text" name="titre" id="titre" onChange={this.handleChange} />
                            <label for="text">Article</label>
                            <input required type="text" name="text" id="text" onChange={this.handleChange} />
                        </div>
                        <br/>
                        <div className="champ">
                                <input  className='text-center' id="btn-subscribe" onClick={this.handleNewArticleClick} type="submit" value="Submit" />
                            </div>
                    </div>
                </section>
            </>
        );
    }
}
export default AddArticle;