import React from "react";
import axios from "axios";

/* --  Reactstrap Components -- */
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import AddArticle from "../../components/Chef/AddArticle.jsx";
import CardChildren from "../../components/Chef/CardChildren.jsx";
import DocumentChef from "../../components/Chef/DocumentChef.jsx";

/* --  Core Components -- */
import { Redirect } from "react-router-dom";
import tools from "../../toolBox"
import "../../assets/css/subscribe.css";
import "../../assets/css/admin.css";
import "../../assets/css/main.css";
import "../../assets/css/login.css";

/* -- FontAwsome -- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
const logout = <FontAwesomeIcon icon={faArrowRightFromBracket} />

class Chef extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            isLoading: true,
            redirected: false,
            token: "",
            children : []
        };
    };

    componentDidMount() {
        if (tools.checkIfConnected()) {
            this.fetchData();
            this.setState({ token: tools.readCookie("Token") })
        } else {
            this.setState({ redirectedChef: true })
            this.setState({ redirectedCU: true })
        }
    }

    deleteCookie(token) {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        window.location.replace("https://www.40iemebierges.be/");
    }

    fetchData() {
        axios.get(this.state.url + '/fetchChildren').then(response => {
            this.setState({
                children: response.data.children,
                isLoading: false
            })
        }).catch(error => {
            console.log(error)
        });
    }


    render() {
        if (this.state.redirected) return (<Redirect to="/login" />)
        if (this.state.isLoading) return (<p>Please wait...</p>);
        return (
            <>
                <Navbar />
                <section className=" section-home home-subscribe">
                    <h1>Bienvenu cher chef</h1>
                </section>
                <main>
                    <CardChildren token={this.state.token} children={this.state.children} />
                    <DocumentChef />
                    <AddArticle token={this.state.token}/>
                    <p className='text-center fixed-logout'><a type="submit" id="btn-subscribe" onClick={this.deleteCookie} >{logout}</a></p><br /><br /><br />
                </main>
                <Footer />
            </>
        );
    }
}

export default Chef;