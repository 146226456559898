import React from "react";

function Footer() {
  return (
    <footer>
      <a href="/mention-legale" title="mentions légales" className="btn-text">mentions légales</a>
      <p>© 2020, site made by Nathan Stiers</p>
    </footer>
  );
}

export default Footer;
