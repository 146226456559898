import React from "react";
import { Redirect } from 'react-router-dom'

/* --  Reactstrap Components -- */
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import CardUser from "../../components/Admin/CardUser.jsx";
import AddUser from "../../components/Admin/AddUser.jsx";
import CardMeeting from "../../components/Admin/CardMeeting.jsx";
import AddMeeting from "../../components/Admin/AddMeeting.jsx";
import CardChildren from "../../components/Chef/CardChildren.jsx";
import AddChildren from "components/Admin/AddChildren.jsx";
import CardArticleToValidated from "../../components/Admin/CardArticleToValidated.jsx";

/* -- Core Components -- */
import axios from "axios";
import tools from "../../toolBox"
import "../../assets/css/subscribe.css";
import "../../assets/css/admin.css";
import "../../assets/css/main.css";
import "../../assets/css/login.css";

/* -- FontAwsome -- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
const logout = <FontAwesomeIcon icon={faArrowRightFromBracket} />


class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            redirected: false,
            users: [],
            meetings: [],
            unfilteredMeetings: [],
            unfilteredUsers: [],
            unLoadArticles : [],
            children : [],
            blogarticles:[],
            url: "https://bierges-40e-api.herokuapp.com",
            token: "",
            meetingsFilter: "",
            usersFilter: "",
            PopupModifyUserOpen: false,
            PopupModifyMeetingOpen: false
        };
        this.findUserPromise = this.findUserPromise.bind(this)
        this.updateUsersList = this.updateUsersList.bind(this)
        this.updateMeetingList = this.updateMeetingList.bind(this)
        this.updateArticlesList = this.updateArticlesList.bind(this)
        this.deleteCookie = this.deleteCookie.bind(this)
    };

    componentDidMount() {
        if (tools.checkIfConnected()) {
            this.fetchData();
            this.setState({ token: tools.readCookie("Token") })
        } else {
            this.setState({ redirected: true })
        }
    }

    updateArticlesList(articleList){
        this.setState({blogarticles:articleList})
    }

    updateChildrenList(childList){
        this.setState({children:childList})
    }
    
    updateUsersList(userList){
        this.setState({users:userList})
    }

    updateMeetingList(meetingList){
        this.setState({meetings:meetingList})
    }

    fetchData() {
        axios.get(this.state.url + '/fetchAllData').then(response => {
            this.setState({
                users: response.data.users,
                children: response.data.children,
                meetings: response.data.meetings,
                blogarticles: response.data.blogarticles,
                unfilteredUsers: response.data.users,
                unLoadArticles: response.data.blogarticles,
                unfilteredMeetings: response.data.meetings,
                isLoading: false
            })
        }).catch(error => {
            console.log(error)
        });
    }

    deleteCookie(token) {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        window.location.replace("https://www.40iemebierges.be/index");
    }

    findUserPromise(idUser) {
        return new Promise((success, failure) => {
            let u = this.state.users.find(user => user._id === idUser);
            console.log(u)
            this.setState({ currentUser: u })
            if (typeof u == 'undefined') {
                alert("fail")
                failure("Error undefined user");
            } else {
                alert("success")
                console.log(this.state.currentUser)
                success(u);
            }
        })
    }


    render() {
        if (this.state.redirected) return (<Redirect to="/login" />)
        if (this.state.isLoading) return (<p>Please wait...</p>);
        return (
            <>
                <Navbar/>
                <section className=" section-home home-subscribe">
                    <h1>Bienvenu cher CU</h1>
                </section>
                <main>
                    <CardUser token={this.state.token} users={this.state.users} oneuser={this.state.oneuser} updateUsersList={this.updateUsersList} /> 
                    <AddUser token={this.state.token}/> 
                    <CardChildren token={this.state.token} children={this.state.children}/>
                    <AddChildren token={this.state.token} children={this.state.children} updateChildrenList={this.updateChildrenList}/>
                    <CardMeeting token={this.state.token} meetings={this.state.meetings} updateMeetingList={this.updateMeetingList}/> 
                    <AddMeeting token={this.state.token} meetings={this.state.meetings} updateMeetingList={this.updateMeetingList}/>
                    <CardArticleToValidated blogarticles={this.state.blogarticles} token={this.state.token} updateArticlesList={this.updateArticlesList}/>
                    <p className='text-center fixed-logout'><a type="submit" id="btn-subscribe" onClick={this.deleteCookie} >{logout}</a></p><br /><br /><br />
                </main>
                <Footer />
            </>
        );
    }
}

export default Admin;