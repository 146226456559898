import React from "react";

/* -- core components -- */
import '../../assets/css/main.css'
import '../../assets/css/admin.css'
import axios from "axios";

class AddUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
            email:"",
            password:"",
            section:"",
            role:""
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleNewUserClick = this.handleNewUserClick.bind(this)
    };

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleNewUserClick() {
        axios.post(this.state.url + '/createUser', {
            email: this.state.email,
            password: this.state.password,
            section: this.state.section,
            role: this.state.role
        }, {
            headers: {
                token: this.props.token
            }
        }).then(response => {
            console.log(response)
            alert("L'article a bien été ajouté")
        }).catch(error => {
            console.log(error)
            alert("Une erreur a été detectée, veuillez contactez le webmaster")
        });
    }

    render() {
        return (
            <>
                <section>
                        <h2>Ajouter un chef :</h2>
                        <div className="form">
                        <div className="champ">
                                <label htmlFor="email">E-mail :</label>
                                <input type="email" id="email-chef" name="email" value={this.state.email} onChange={this.handleChange} />
                                <label htmlFor="password">Mot de passe :</label>
                                <input type="password" name="password" id="password" placeholder="*****" value={this.state.password} onChange={this.handleChange} />
                                <label htmlFor="role">Rôle : </label>
                                <select value={this.state.role} name="role" id="role" onChange={this.handleChange}>
                                    <option value="CU">CU</option>
                                    <option value="CHEF" selected>CHEF</option>
                                    <option value="PARENT">PARENT</option>
                                </select>
                                <label htmlFor="section">Section :</label>
                                <select name="section" value={this.state.section} id="section" onChange={this.handleChange}>
                                    <option value="NULL" selected>PAS DE SECTION</option>
                                    <optgroup label="4 à 7 ans"/>
                                    <option value="NUTONS">NUTONS</option>
                                    <optgroup label="8 à 11 ans"/>
                                    <option value="LUTINS">LUTINS</option>
                                    <option value="LOUPS">LOUPS</option>
                                    <optgroup label="12 à 16 ans"/>
                                    <option value="GUIDES">GUIDES</option>
                                    <option value="SCOUTS">SCOUTS</option>
                                    <optgroup label="17 ans et plus"/>
                                    <option value="HORIZONS">HORIZONS</option>
                                    <option value="ROUTE">ROUTE</option>
                                </select>
                            </div>
                            <br/>
                            <div className="champ">
                                <input  className='text-center' id="btn-subscribe" onClick={this.handleNewUserClick} type="submit" value="Submit" />
                            </div>
                        </div>
                           
                    </section>
            </>
        );
    }
}
export default AddUser;