import React from "react";

// reactstrap components

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import axios from "axios"
import aventure from '../../assets/img/aventure.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolleyball, faCompass, faPersonHiking,faCloudSun, faFlag } from '@fortawesome/free-solid-svg-icons'

const volleyball = <FontAwesomeIcon icon={faVolleyball} />
const compass = <FontAwesomeIcon icon={faCompass} />
const personHiking = <FontAwesomeIcon icon={faPersonHiking} />
const cloudSun = <FontAwesomeIcon icon={faCloudSun} />
const flag = <FontAwesomeIcon icon={faFlag} />

class MainGuides extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetings: [],
            url: "https://bierges-40e-api.herokuapp.com"
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.state.url + '/fetchMeetings/GUIDES').then(response => {
            this.setState({ meetings: response.data.meetings, isLoading: false })
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
                <Navbar />
                <section className="section-home home-guide">
                    <h1>Les aventures</h1>
                    <p><em>La prise de responsabilités</em></p>
                </section>
                <main>
                    <section className="intro-nuton">
                        <article>
                            <img src={aventure} alt="" />
                        </article>
                        <article>
                            <p> La Compagnie Guide accueille des guides aventures de 13 à 17 ans et se compose de différentes patrouilles.</p>
                            <p>Dans chaque patrouille (équipe de 6 à 8 guides), on retrouve une CP (chef de patrouille) et une SP (seconde de patrouille).
                                Les autres guides de la patrouille ont également l’occasion de prendre différentes responsabilités : woodcraft, cuisine, pharmacie, …</p>
                        </article>
                    </section>
                    <section>
                        <h2>Être guide c'est...</h2>
                        <h3>S’engager et progresser sur le plan humain mais également sur le plan des compétences techniques. C’est apprendre à évoluer, s’épanouir en groupe, découvrir des valeurs humaines, être actif et s’engager dans des projets et vivre des aventures passionnantes.</h3>
                        <p>Dans la Compagnie, les adolescentes ont l’occasion d’assumer de plus grandes responsabilités, de s’engager dans de petits projets collectifs, à leur portée, utiles pour les autres et dans lesquels elles ont un rôle à jouer.
                            Les guides évoluent sur les chemins de l’Aventure à travers 5 contrées :</p>
                        <article>
                            <figure className="background-white">
                            <div className="icone-section icone-scout">{volleyball}</div>
                                <h4>Plein jeu</h4>
                            </figure>
                            <figure className="background-white">
                            <div className="icone-section icone-scout">{compass}</div>
                                <h4>Quatre vents</h4>
                            </figure>
                            <figure className="background-white">
                            <div className="icone-section icone-scout">{cloudSun}</div>
                                <h4>Saison</h4>
                            </figure>
                            <figure className="background-white">
                            <div className="icone-section icone-scout">{personHiking}</div>
                                <h4>Troubadour</h4>
                            </figure>
                            <figure className="background-white">
                            <div className="icone-section icone-scout">{flag}</div>
                                <h4>Sans frontière</h4>
                            </figure>
                        </article>
                        <p> Ces contrées permettent aux jeunes de grandir, de progresser et d’assumer des responsabilités dans des projets variés. Les badges et le carnet de l'Aventure sont, dans ces buts, deux outils privilégiés.<br /><br />
                            Lors de son premier camp, la guide reçoit un totem ; un nom d’animal. La totémisation et la réalisation de défis personnels marquent son intégration dans la Compagnie. Le totem est choisi dans le respect de l’individu en fonction de son aspect physique, ses attitudes, ses mimiques et son comportement général.<br /><br />
                            Plus tard, le quali vient compléter le totem. Il s’agit d’une phrase ou mot qui représente les qualités et le comportement de l’ado.<br /><br />
                            La devise « Toujours prête » est commune à tous les guides et scouts du monde.</p>
                    </section>
                    <section>
                        <h2> La loi guide </h2>
                        <h3>Permet au jeune de découvrir des valeurs, de les expérimenter et de choisir d’y adhérer, notamment par la promesse.</h3>
                        <ul className="list-item">
                            <li>La guide est vraie, elle mérite et fait confiance.</li>
                            <li>La guide est loyale, elle tient ses engagements.</li>
                            <li>La guide rend service.</li>
                            <li>La guide veut être l’amie de tous.</li>
                            <li>La guide est courtoise, elle est attentionnée.</li>
                            <li>La guide aime la nature et protège la vie, elle peut y découvrir l’œuvre de Dieu.</li>
                            <li>La guide sait obéir, elle va jusqu’au bout.</li>
                            <li>La guide partage sa joie de vivre.</li>
                            <li>La guide respecte le bien commun et participe à l’effort de tous.</li>
                            <li>La guide est simple, elle a du respect pour elle-même et pour les autres.</li>
                        </ul>
                        
                    </section>
                    <section className="section-staff">
                        <h2>Le staff</h2>
                        <ul className="list-item">
                            <li>Ezis</li>
                            <li>Caligata </li>
                            <li>Yamaniko </li>
                            <li>Bengal </li>
                        </ul>
                        <p>Pour toutes informations sur la chaumière veuillez nous contacter par mail<br />
                            mail: <a href="mailto:guides.bierges@gmail.com"> guides.bierges@gmail.com </a></p>
                    </section>
                    <section id="activity-desktop"> 
                    <h2>Les rendez-vous</h2>
                    <div className="activity">
                            <h3>Quand ?</h3>
                            <h3>Quoi ?</h3>
                            <h3>Où ?</h3>
                        </div>
                        {this.state.meetings.map((meeting, index) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <div className="activity">
                                    <p>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</p>
                                    <p>{meeting.libelle}</p>
                                    <p>{meeting.infos}</p>
                                </div>
                            )
                        })}
                    </section>
                    <section id="activity-mobile">
                    <h2>Les rendez-vous</h2>
                        {this.state.meetings.map((meeting) => {
                            let dateNumbers = meeting.date.split('T')[0].split('-')
                            return (
                                <ul className="activity list-item">
                                    <li>Le {dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}</li>
                                    <li>{meeting.libelle}</li>
                                    <li>{meeting.infos}</li>
                                </ul>
                            )
                        })}
                    </section>
                </main>
                    <Footer />
          
            </>
        );
    }
}

export default MainGuides;
