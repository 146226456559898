import React from "react";

// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footers/Footer.js";
import CardArticle from "../../components/CardArticle.jsx";
import '../../assets/css/blog.css'
import '../../assets/css/main.css'
import axios from "axios"



class Blog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetings: [],
            blogarticles:[],
            titre:"",
            text:"",
            auteur:"",
            date:"",
            url: "https://bierges-40e-api.herokuapp.com",
            validated:false
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.state.url + '/fetchBlog').then(response => {
            this.setState({
                blogarticles: response.data.blogarticles,
                isLoading: false
            })
        }).catch(error => {
            console.log(error)
        });
    }

    render() {
        return (
            <>
                <Navbar facebookLink="https://www.facebook.com/groups/1290833304584351/" />
                <section className="section-home home-blog">
                    <h1>Le blog</h1>
                    <p className="presentation-subtitle text-center"><em>Suivez l'actualité de la 40e</em></p>
                </section>
                <main>
                <CardArticle blogarticles={this.state.blogarticles} />  
                </main>
                <Footer />

            </>
        );
    }
}

export default Blog;
