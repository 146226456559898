import React from "react";

/* -- core components -- */
import '../assets/css/main.css'
import '../assets/css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUser } from '@fortawesome/free-solid-svg-icons'
const pictoDate = <FontAwesomeIcon icon={faCalendar} />
const pictoUser = <FontAwesomeIcon icon={faUser} />

class CardArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://bierges-40e-api.herokuapp.com",
        };

    };


    render() {
        return (
            <>
                <section>
                    <h2>L'unité de Bierges regorge d'actualité</h2>
                </section>
                <section>
                    <article className="blog-article">
                        {this.props.blogarticles.map((article, index) => {
                                let dateNumbers = article.date.split('T')[0].split('-')
                                if(article.validated){
                                return (
                                    <div className="containerItemAdmin" id={article._id}>
                                        <h3>{article.titre}</h3>
                                        <p>{article.text}</p>
                                        <ul className="infos-pratique">
                                            <li><div className="icone">{pictoDate}</div>{dateNumbers[2]}-{dateNumbers[1]}-{dateNumbers[0]}  </li>
                                            <li><div className="icone">{pictoUser}</div>{article.auteur} </li>
                                        </ul>
                                    </div>
                                )
                            }
                        })}
                    </article>
                </section>
            </>
        );
    }
}
export default CardArticle;